#ubicacion{
    height: 680px;
    width: 100%;
    background-color: lightslategray;
    .map{
        width: 100%;
        height: 100%;
    }
    h2{
        color:white;
    }   
}
